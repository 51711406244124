exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktuelt-jsx": () => import("./../../../src/pages/aktuelt.jsx" /* webpackChunkName: "component---src-pages-aktuelt-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-foreninger-jsx": () => import("./../../../src/pages/foreninger.jsx" /* webpackChunkName: "component---src-pages-foreninger-jsx" */),
  "component---src-pages-sok-jsx": () => import("./../../../src/pages/sok.jsx" /* webpackChunkName: "component---src-pages-sok-jsx" */),
  "component---src-templates-2-column-jsx": () => import("./../../../src/templates/2column.jsx" /* webpackChunkName: "component---src-templates-2-column-jsx" */),
  "component---src-templates-event-date-picker-jsx": () => import("./../../../src/templates/event-date-picker.jsx" /* webpackChunkName: "component---src-templates-event-date-picker-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-kurs-jsx": () => import("./../../../src/templates/kurs.jsx" /* webpackChunkName: "component---src-templates-kurs-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-stream-jsx": () => import("./../../../src/templates/stream.jsx" /* webpackChunkName: "component---src-templates-stream-jsx" */)
}

